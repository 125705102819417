.homepagemaindiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.font_link {
    font-family: 'Sora', sans-serif;
}

.ant-table table { font-family: 'Sora', sans-serif;}

.aboutMainBody {
    font-family: "Lato", sans-serif;
    font-weight: bold;
}

.articleMainBody {
    font-family: "Lato", sans-serif;
}

.catCol_1 {
    width: 50vw;
    height: 8vw;
    background: rgba(230, 106, 215, 0.5);
}

.catCol{
    width: 50vw;
    height: 8vw;
    background: rgba(230, 106, 215, 0.5);
    }

.infoboxStore {
        background-color: #fff;
}

.catColText {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0;
    color: black;
    text-align: center;
}

.jsonRowText {
    display:flex;
    flex-direction:row;
}

.productRangeIcon {
    width: 100px;
    height: 100px;
    padding-top: 15px;
    margin: 0 auto;
    display: block;
}