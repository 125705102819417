.footer {
    text-align: center;
    color: aliceblue;
    position: relative;
    bottom: 75px;
    padding-top: 30px;
    margin: auto;
    font-size: 15px;
}

.ant-descriptions .ant-descriptions-row > th, .ant-descriptions .ant-descriptions-row > td {
     padding-bottom: 0px !important;
}


.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after,
.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu-item-selected {
    border-top-radius: 5px !important;
    background-color: #ffffff !important;
    color: Black !important;
    border-bottom: 0px solid black !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.ant-menu > .ant-menu-item{
    font-family: Verdana, sans-serif !important;
    font-weight: lighter;
}

.ant-card-meta-title {
    white-space: pre-line !important;
}


.logo {
    align-self: center;
    opacity: 100%;
}

.header {
        background-color: #424243;
        top: 0;
        zIndex: 1;
}

.loginButton {
    color: rgb(255, 255, 255);
    margin-right: 40px;
    margin-top: 20px;
    font-size: large;
}


.ant-menu-submenu-selected {
    background-color: #1677ff;
}




.ant-menu-item {
    text-align: bottom;
    font-size: large;
    font-family: 'Sora', sans-serif;
    position: 'absolute';
    bottom: 0;
    zIndex: 1;
    transition: 'all 0.2s';
}

.font_link {
    font-family: 'Sora', sans-serif;
}

.ant-btn-text:not(:disabled):hover {
    color: #cccccc;
}

.ant-btn {
    padding-left: 0px;
}

.closeButton {
    color: #ffffff;
}

.anticon-close {
    color: #ffffff;
    height: 30px;
    width: 30px;
}

.hamburger {
    position: absolute;
    height: 60px;
    width: 60px;
}

.footerMobile {
    display: block;
    margin-left: 25%;
    width: 50%;
    margin-right: auto;
    text-align: center;
    position: relative;
    color: #ffffff;
    background-color: #000000;
    padding-bottom: 30px;
    padding-top: 30px;
    bottom: 0;
}

.loginMobile {
    position: absolute;
    right: 0;
}

.mobileLoginButton {
    color: #ffffff;
    margin-right: 16px;
    margin-top: 20px;
    font-size: 16px;
}

